document.addEventListener("DOMContentLoaded", () => {

  headerFixed();
  changeOverlayOpacity();

  window.addEventListener('scroll', () => {

    headerFixed();
    changeOverlayOpacity();
  });

  let resizeTimer;
  window.addEventListener("resize", () => {
      document.body.classList.add("resize-animation-stopper");
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
          document.body.classList.remove("resize-animation-stopper");
      }, 1000);
  });

  if (document.querySelector(".swiper")) {
    const swiper = new Swiper('.swiper', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 30,
      autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
      },
      navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        576: {
          slidesPerView: 3,
          spaceBetween: 60
        }
      }
    });
  }
});

function headerFixed() {

  if (window.pageYOffset > 0) {
    document.querySelector("body").classList.add("header-fixed");
  } else if (window.pageYOffset == 0) {
    document.querySelector("body").classList.remove("header-fixed");
  }
}

function changeOverlayOpacity() {

  if (!document.querySelector("body").classList.contains("main-page") && document.querySelector(".overlay-main")) return false;
  var topOfWindow = window.pageYOffset;
  if (topOfWindow > 400) topOfWindow = 400;
  if (document.querySelector(".overlay-main")) document.querySelector(".overlay-main").style.opacity = topOfWindow / 500;
}

$(".content__head").on("click", function() {
	$(this).toggleClass("content__head--show");
	$(this).parent().find(".content__text").slideToggle("100", "linear");
})

new WOW().init();

$(".down").on("click", function() {

  const heightWindow = window.innerHeight;
  $('html').animate({ scrollTop: heightWindow - 85 }, 800);
});

$(".popup").fancybox({
  padding : 0,
  touch: false,
  transitionEffect: "slide",
});

var menuLink = "";

$(".header-nav__item.header-nav__item--wrap > a").on('touchstart', function(event) {

  if ($(".menu-mobile").hasClass("active")) {
    event.preventDefault();
    event.stopPropagation();
    menuLink = $(this).parent();
    menuLink.addClass("opened");
    $(".mobile-back").addClass("show");
  }
})

$(".submenu-item.submenu-item--inner > a").on('touchstart', function(event) {

  event.preventDefault();
  event.stopPropagation();
  menuLink = $(this).parent();
  menuLink.addClass("opened");
})

$(".mobile-back").on('touchstart', function() {

  if ($(".header-nav__item").find(".submenu-item").hasClass("opened")){
    $(".submenu-item").removeClass("opened");
  } else {
    $(".header-nav__item").removeClass("opened");
    $(".navi_item-back").removeClass("show");
  }
});

$(".menu-mobile-open").on('touchstart ', function() {

  $(".menu-mobile").addClass('active');
  $("body, html").css('overflow', 'hidden');
});

$(".mobile-close").on('touchstart ', function() {

  $(".menu-mobile").removeClass('active');
  $("body, html").css('overflow', 'auto');
});

const [tabs, tabsPanels] = [
	Array.from(document.querySelectorAll(".tabs li")),
	Array.from(document.querySelectorAll(".tabs-panel"))
];

tabs.forEach((tab) => {

	tab.addEventListener("click", () => {

		const target = document.querySelector(`#${tab.dataset.target}`);
		removeActiveClass([tabs, tabsPanels]);
		tab.classList.add("active");
		target.classList.add("active");
	});
});

const removeActiveClass = (el) => {

	el.forEach((item) => {
		item.find((e) => e.classList.contains("active")).classList.remove("active");
	});
};

$(document).on('af_complete', function(event, response) {
  if (response.success != false) {
      var form = response.form;
      $.fancybox.close();
  }
});

$(document).on('af_complete', function(event, response) {
  var form = response.form;
  if (response.success) {
      $.fancybox.close();
      $.fancybox.open({src:'#callback_form-status'});
  }
});

$(".price-tabs .btn-primary").on("click", function() {

  let title = $(this).closest("li").find("a").text();

  $("#request-det input[name='service']").val(title);
});

var inps = $("input.here_check_option");
inps.change(function() {
    var ololo = '';
    var ololo2 = 0;
    var ololo3 = '';
    inps.each(function() {
        if ($(this).is(':checked')) {
            ololo += '<li>' + $(this).val() + '</li>';
            if($.isNumeric(parseInt($(this).attr('data-price'))) == true)
            {
                ololo2 += parseInt($(this).attr('data-price'));
            }
            ololo3 += 'Услуга: '+ $(this).val() + ' Цена:' + $(this).attr('data-price') + '||';
        }
    });
    
    if (ololo2=='' || $.isNumeric(ololo2) == false)
    {
    ololo2=0;
    }
    $('#const_price').html(ololo2)
    $('.check_ul').html(ololo);
    $('#selected_calc_input').val(ololo3+'||На общую сумму: '+ololo2);
});
   
$('.inputGroup').on('click',function(){
    var padding ='';
     var padding2 ='';
      if ($(window).width() <= '995'){
     
          padding =  '20px 0px 0px 0px';
        padding2 =  '20px 10px 0px 20px';
    } else {
          padding =  '20px 0px 0px 0px';
         padding2 =  '20px 10px 0px 40px';
    }
     if ($(this).find('input').prop('checked') === false)
     {
    $(this).parent('li').css('background', 'none');
    $(this).parent('li').css('padding', padding);
     }
     else
     {
     
     $(this).parent('li').css('background', 'rgba(171, 171, 171, 0.2)');
     $(this).parent('li').css('padding', padding2);
     }
     
});

if ($(window).width() <= '753'){
  $('div').removeClass('wow');
}

